.blurbGrid {
  padding: 0 1.5rem;
  display: grid;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(25, 1fr);
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: clamp(1rem, 0.8852rem + 0.4898vw, 1.375rem);
  color: hsla(159, 42%, 88%, 1);
}
.blurbGridItem {
  grid-column-start: 8;
  grid-column-end: 19;
  padding-bottom: max(
    2.652rem,
    calc(2.652rem + (84 - 42) * ((100vw - 375px) / (1600 - 375)))
  );
  padding-top: max(
    2.652rem,
    calc(2.652rem + (84 - 42) * ((100vw - 375px) / (1600 - 375)))
  );
}
.blurbP {
  line-height: 160%;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 1rem;
  text-align: justify;
}

@media (max-width: 560px) {
  .blurbGridItem {
    grid-column-start: 2;
    grid-column-end: 24;
  }
}
