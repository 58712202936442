.cards {
  margin-top: max(
    2.652rem,
    calc(2.652rem + (84 - 42) * ((100vw - 375px) / (1600 - 375)))
  );
  padding: 0 4rem 4rem;
  box-sizing: border-box;
  display: block;
  max-width: 1200px;
}

.card-content {
  display: flex;
  flex-direction: column;
  grid-row-gap: 5rem;
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(25, 1fr);
  box-sizing: border-box;
}
@media (max-width: 850px) {
  .cards {
    padding: 0 3.5rem 1rem;
  }
}
@media (max-width: 815px) {
  .cards {
    padding: 0 7rem 1rem;
  }
}
@media (max-width: 630px) {
  .cards {
    padding: 0 6rem 1rem;
  }
  .card-content {
      grid-row-gap: 4rem;
  }
}
@media (max-width: 570px) {
  .cards {
    padding: 0 5rem 1rem;
  }
}
@media (max-width: 480px) {
  .cards {
    padding: 0 4rem 1rem;
  }
  .card-content {
    grid-row-gap: 3rem;
}
}
@media (max-width: 440px) {
  .cards {
    padding: 0 3rem 1rem;
  }
}
@media (max-width: 390px) {
  .cards {
    padding: 0 2.8rem 1rem;
  }
}
@media (max-width: 375px) {
  .cards {
    padding: 0 2.6rem 1rem;
  }
}