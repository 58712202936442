.pics {
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto
    max(2.652rem, calc(2.652rem + (84 - 42) * ((100vw - 375px) / (1600 - 375))));
  gap: 1.5rem;
}

.largePic {
  border-radius: 3rem;
  overflow: hidden;
  display: block;
  transition: all 0.2s ease-in-out;
}
.smallPic {
  margin-top: 0;
  border-radius: 3rem;
  overflow: hidden;
  display: block;
  transition: all 0.2s ease-in-out;
}
.picWrap {
  background: hsla(160, 52%, 71%, 0.1);
  position: relative;
  margin: 0;
  display: block;
}
.pic {
  object-fit: cover;
  width: 100%;
  max-width: inherit;
  position: relative;
  z-index: 50;
  display: block;
  height: 542px;
  overflow-clip-margin: content-box;
  overflow: clip;
}
.largePic:hover {
  box-shadow: 0 0.25rem 0.5rem 0.3rem rgba(0, 0, 0, 0.1);
  transform: translateY(-3.5px);
}

.smallPic:hover {
  box-shadow: 0 0.25rem 0.5rem 0.3rem rgba(0, 0, 0, 0.1);
  transform: translateY(-3.5px);
}
@media (max-width: 750px) {
  .pic {
    height: 280px;
  }
  .pics {
    padding: 0 1rem;
  }
}
@media (max-width: 560px) {
  .pic {
    height: 200px;
  }
  .pics {
    padding: 0 1rem;
  }
}
