.tight {
  padding: 12rem 0 3rem;
  position: relative;
  text-align: center;
  font-size: clamp(3.2rem, 0.5692rem + 6.238vw, 8rem);
  line-height: 1.2;
  letter-spacing: -2px;
  font-family: "acorn", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: hsla(160, 52%, 71%, 1);
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1100px;
}

.hero {
  display: block;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.holder {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.holder-content {
  position: relative;
  display: block;
  z-index: 10;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.right-star {
  right: -12rem;
  top: -7rem;
  transform: scale(1);
  position: absolute;
  display: block;
  z-index: 10;
  box-sizing: border-box;
}

.left-star {
  left: -10rem;
  bottom: -6.5rem;
  transform: scale(1);
  position: absolute;
  display: block;
  z-index: 10;
  box-sizing: border-box;
}

.star {
  position: relative;
  margin: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  box-sizing: border-box;
}

img {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  max-width: inherit;
  position: relative;
  z-index: 50;
  max-width: 100%;
  display: block;
}
.wrapper {
  padding: 0rem 8rem 0rem 8rem;
  display: grid;
  grid-template-columns: 1fr min(65ch, 100%) 1fr;
  position: relative;
  box-sizing: border-box;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: hsla(160, 52%, 71%, 1);
}

.center {
  font-size: clamp(1rem, 0.8852rem + 0.4898vw, 1.375rem);
  text-align: center;
  grid-column: 2;
  color: hsla(159, 42%, 88%, 1);
  letter-spacing: -0.3px;
  line-height: 160%;
  margin: 0;
  box-sizing: border-box;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.subtitle {
  display: block;
  box-sizing: border-box;
}

.details {
  display: grid;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: auto auto;
  margin-bottom: var(--spacing-l);
  box-sizing: border-box;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: hsla(160, 52%, 71%, 1);
  margin-bottom: 6rem;
}
.me {
  grid-column-start: 3;
  grid-column-end: 11;
  position: relative;
  grid-row: 1;
}
.me-content {
  position: relative;
}

.hero-img-wrap {
  border-radius: 200px 200px 0 0;
  position: relative;
  margin: 0;
  color: hsla(160, 52%, 71%, 0.1);
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.hero-img {
  object-fit: cover;
  width: 100%;
  border-radius: 200px;
  transition: all 0.2s ease-in-out;
  max-width: inherit;
  position: relative;
  display: block;
}

.blurb {
  grid-column-start: 12;
  grid-column-end: 24;
  margin-top: var(--24px);
  grid-row: 1;
}
.intro {
  margin-top: max(
    1.5rem,
    calc(1.5rem + (48 - 24) * ((100vw - 375px) / (1600 - 375)))
  );
  margin-bottom: max(
    0.875rem,
    calc(0.875rem + (28 - 14) * ((100vw - 375px) / (1600 - 375)))
  );
  font-size: clamp(1.875rem, 1.301rem + 2.1vw, 2.75rem);
  line-height: 110%;
  font-family: "acorn", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  margin: 0;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.blurb-content {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 0;
  font-size: clamp(1rem, 0.8852rem + 0.4898vw, 1.375rem);
  line-height: 160%;
  margin-top: max(
    0.875rem,
    calc(0.875rem + (28 - 14) * ((100vw - 375px) / (1600 - 375)))
  );
  color: hsla(159, 42%, 88%, 1);
}
.button {
  font-size: clamp(0.875rem, 0.7985rem + 0.3265vw, 1.125rem);
  text-decoration: none;
  background: hsla(160, 52%, 71%, 1);
  color: hsla(158, 23%, 18%, 1);
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  text-align: center;
  letter-spacing: -0.3px;
}
@media (max-width: 1600px) {
  .tight {
    padding: 8rem 0 2rem;
    font-size: clamp(3.2rem, 0.5692rem + 7.2vw, 6rem);
  }
}

@media (max-width: 1200px) {
  .tight {
    padding: 8rem 0 1.5rem;
    font-size: clamp(3.2rem, 0.5692rem + 8.2vw, 5.9rem);
  }
  .wrapper {
    padding: 0 10rem 0 10rem;
  }
  .right-star {
    right: -11rem;
    top: -7rem;
  }
  
  .left-star {
    left: -9rem;
    bottom: -6.5rem;
  }
}
@media (max-width: 815px) {
  .wrapper {
    padding: 0 6rem 0 6rem;
  }
  .right-star {
    right: -9.5rem;
    top: -5.4rem;
  }
  
  .left-star {
    left: -7.9rem;
    bottom: -5.2rem;
  }
  .star-img {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 750px) {
  .tight {
    padding: 8rem 8rem 1.5rem 8rem;
  }
  .right-star {
    right: -8.2rem;
    top: -4.5rem;
  }
  .left-star {
    left: -2.3rem;
    bottom: -4.3rem;
  }

  .me {
    grid-row: 1;
    grid-column-start: 3;
    grid-column-end: 24;
  }
  .blurb {
    grid-row: 2;
    grid-column-start: 3;
    grid-column-end: 24;
  }
  .hero-img {
    height: 275px;
  }
  .hero-img-wrap {
    margin-block-start: 0em;
  }
  .details {
    margin-bottom: 3rem;
  }



}
@media (max-width: 700px) {
  .left-star {
    left: -3rem;
    bottom: -4.3rem;
  }
}
@media (max-width: 650px) {
  .left-star {
    left: -3.3rem;
    bottom: -4rem;
  }
  .right-star {
    right: -7rem;
    top: -4.5rem;
  }
  .star-img {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 600px) {
  .left-star {
    left: -4.2rem;
    bottom: -3.7rem;
  }
}
@media (max-width: 550px) {
  .left-star {
    left: -4.8rem;
    bottom: -3.7rem;
  }
  .star-img {
    width: 43px;
    height: 43px;
  }
}

@media (max-width: 500px) {
  .wrapper {
    padding: 0 3rem 0 3rem;
  }
  .left-star {
    left: -5.5rem;
    bottom: -3rem;
  }
  .right-star {
    right: -6.5rem;
    top: -4rem;
  }
  .star-img {
    width: 38px;
    height: 38px;
  }
}
@media (max-width: 465px) {
  .tight {
    padding: 8rem 6rem 1.5rem 6rem;
  }
  .wrapper {
    padding: 0 0.5rem 0 0.5rem;
  }
  .center {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .left-star {
    left: -5.8rem;
    bottom: -3rem;
  }
  .right-star {
    right: -6.5rem;
    top: -3.8rem;
  }
  .me {
    grid-row: 1;
    grid-column-start: 3;
    grid-column-end: 24;
  }
  .blurb {
    grid-row: 2;
    grid-column-start: 3;
    grid-column-end: 24;
  }
  .hero-img {
    height: 275px;
  }
  .hero-img-wrap {
    margin-block-start: 0em;
  }
  .details {
    margin-bottom: 3rem;
  }
}

@media (max-width: 400px) {
  .tight {
    padding: 8rem 2rem 1.5rem 2rem;
  }

  .left-star {
    left: -2.8rem;
    bottom: -3rem;
  }
  .right-star {
    right: -5.7rem;
    top: -3.8rem;
  }
  .star-img {
    width: 35px;
    height: 35px;
  }
  .wrapper {
    padding: 0 0.25rem 0 0.25rem;
  }
}
@media (max-width: 350px) {
  .left-star {
    left: -3.5rem;
    bottom: -3rem;
  }
  .right-star {
    right: -5.7rem;
    top: -3.8rem;
  }
  .star-img {
    width: 33px;
    height: 33px;
  }
}
