.card-box {
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 4rem;
}
.card-box:hover {
  box-shadow: 0 1rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}
.card-box:hover .inner {
  margin-top: 3.6rem;
}

.card {
  max-height: 400px;
  border-radius: 4rem;
  width: 100%;
  color: hsla(171, 11%, 24%, 1);
  background: var(--_background);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  box-sizing: border-box;
  overflow: hidden;
}

.card-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.info {
  text-align: left;
  display: flex;
  flex-direction: column;
  z-index: 100;
  box-sizing: border-box;
  line-height: 1.5;
  padding-top: 1.9rem;
  padding-left: 1.9rem;
}

.inner {
  z-index: 50;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-left: 5rem;
}

.proj-title {
  font-size: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: left;
  font-weight: 500;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h2 {
  font-size: clamp(1.5rem, 1.0982rem + 1.7143vw, 2.8125rem);
  line-height: 130%;
  font-family: "acorn", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  margin: 0;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.image-holder {
  width: 102%;
  transform: translateY(-4px);
}

figure {
  margin: 0;
}

.card-image {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 50;
}


@media (max-width: 1000px) {
  .card {
    max-height: 380px;
  }
}
@media (max-width: 950px) {
  .card {
    max-height: 350px;
  }
}
@media (max-width: 910px) {
  .card {
    max-height: 320px;
  }
}
@media (max-width: 850px) {
  .card {
    max-height: 300px;
  }
}


@media (max-width: 815px) {
  .card {
    max-height: 475px;
  }
  .card-wrap {
    flex-direction: column;
    width: 100%;
  } 
  .inner {
    margin-left: 0rem;
    margin-top: 2rem;
  }
  .image-holder {
    width: 102%;
    transform: translateX(-2px);
  }
}
@media (max-width: 745px) {
  .card {
    max-height: 450px;
  }
}
@media (max-width: 700px) {
  .card {
    max-height: 425px;
  }
}
@media (max-width: 665px) {
  .card {
    max-height: 400px;
  }
}
@media (max-width: 630px) {
  .card {
    max-height: 380px;
  }}
  @media (max-width: 480px) {
    .card {
      max-height: 350px;
    }
}
@media (max-width: 461px) {
  .card {
    max-height: 330px;
  }
}

@media (max-width: 410px) {
  .card {
    max-height: 315px;
  }
}
@media (max-width: 400px) {
  .card {
    max-height: 305px;
  }
}

