.socialHolder {
  padding: 0 4rem;
  margin-top: 5rem;
}

.socialGrid {
  margin-bottom: max(
    2.652rem,
    calc(2.652rem + (84 - 42) * ((100vw - 375px) / (1600 - 375)))
  );
  display: grid;
  justify-content: center; /* Add this line */
  max-width: 900px;
  margin: 0 auto;
  width: 100%; /* Adjust this line */
}

.socialLink {
  color: hsla(158, 23%, 18%, 1);
  background: hsla(160, 52%, 71%, 1);
  border-radius: 1rem;
  text-align: center;
  text-decoration: none;
  position: relative;
  display: inline-block;
  width: 65px;
  height: 65px;

  margin: 0 1rem;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialLink:hover {
  background: hsla(159, 42%, 88%, 1);
}

@media (max-width: 575px) {
  .socialLink {
    width: 50px;
    height: 50px;
    margin: 0 0.7rem;
  }
  .socialHolder {
    margin-top: 2.5rem;
  }
}
@media (max-width: 750px) {
  .socialGrid {
    row-gap: 1rem;
  }
  .socialHolder {
    margin-top: 2.5rem;
  }
}
@media (max-width: 450px) {
  .socialHolder {
    margin-top: 1.5rem;
  }
}