.skillsWrap {
  display: content;
}
.skillsGrid {
  grid-template-columns: repeat(39, 1fr);
  display: grid;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(35, 56, 49);
  grid-row-gap: 2.5rem;
}
