@font-face {
  font-family: "acorn";
  src: local("acorn"), url("./fonts/acorn.woff") format("woff");
}

@font-face {
  font-family: "gt";

  src: local("gt"), url("./fonts/gt.woff") format("woff");
}

@font-face {
  font-family: "benz";

  src: local("benz"), url("./fonts/benz.woff") format("woff");
}

.App {
  text-align: center;
}

body {
  line-height: 1.5;
}

header {
  padding: 10px;
}

main {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.content {
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 1200px; 
  margin: 0 auto; 
  padding: 20px; 
  box-sizing: border-box; 
}

@media (max-width: 1200px) {
  .content {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 5px;
  }
}