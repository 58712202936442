.grid {
  display: grid;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: auto auto;
  padding: max(4rem, calc(4rem + (128 - 64) * ((100vw - 375px) / (1600 - 375))))
    0;
  position: relative;
  z-index: 50;
}

.fun {
  display: flex;
}
.music {
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
  margin-top: -6rem;
  grid-column-start: 5;
  grid-column-end: 17;
  display: flex;
  grid-row: 1;
}
.wrapper-foot {
  display: grid;
  grid-template-columns: 1fr min(65ch, 100%) 1fr;
  position: relative;
  box-sizing: border-box;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: hsla(160, 52%, 71%, 1);
  background: hsla(160, 52%, 71%, 0.1);
  border-radius: 50px;
  padding: 1rem 2.652rem 1rem 1.25rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.icon {
  display: flex;
  border-radius: 50%;
  opacity: 0.5;
  display: contents;
  fill: hsla(160, 52%, 71%, 0.5);
}
svg {
  margin-top: 0.15rem;
}
.track {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  margin-left: 1.25rem;
  gap: 2px;
}
.action {
  opacity: 0.8;
  font-size: 0.93rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.song {
  letter-spacing: 0;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.93rem;
}
.music a {
  color: inherit;
  text-decoration: inherit;
}
.legal {
  display: flex;
  gap: 10px;
  position: relative;
  color: hsla(159, 42%, 88%, 1);
  font-size: clamp(0.875rem, 0.7985rem + 0.3265vw, 1.125rem);
  font-family: "acorn", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  grid-row: 2;
  grid-column-start: 5;
  grid-column-end: 10;
  margin-top: -6rem;
}
.legal-content {
  position: relative;
}
.list-elsewhere {
  grid-column-start: 19;
  grid-column-end: 21;
  grid-row: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
  padding-inline-start: 40px;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.h6 {
  letter-spacing: 0;
  font-size: clamp(0.875rem, 0.7985rem + 0.3265vw, 1.125rem);
  margin: 0 0
    calc(
      max(
          0.875rem,
          calc(0.875rem + (28 - 14) * ((100vw - 375px) / (1600 - 375)))
        ) - 10px
    );
  letter-spacing: -0.25px;
  font-weight: 600;
  color: hsla(160, 52%, 71%, 1);
}
.list-item {
  margin: 0;
  background: none;
  color: hsla(159, 42%, 88%, 1);
  text-decoration: none;
  display: block;
  padding: 0.35rem 0;

  border: 0;
  transition: color 0.2s ease-in-out;
  border-radius: calc(1.25rem - 10px);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.list-item:hover {
  color: hsla(160, 52%, 71%, 1);
}
@media (max-width: 560px) {
  .list-elsewhere {
    margin-top: 1.5rem;
    padding-inline-start: 0;
  }

  .list-item {
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 15px;
  }
  .music {
    grid-row: 1;
    margin-top: 0rem;
    grid-column-start: 5;
    grid-column-end: 23;
  }
  .list-elsewhere {
    grid-row: 2;
    margin-top: 2.5rem;
    grid-column-start: 5;
    grid-column-end: 29;
  }
  .legal {
    grid-row: 3;
    margin-top: 2.5rem;
    grid-column-start: 5;
    grid-column-end: 21;
  }
}
