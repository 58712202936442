header {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  display: block;
  text-rendering: optimizeLegibility;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 1.25rem auto 0;
  position: relative;
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  border-radius: 50px;
  transition: all 0.2s linear;
  padding: calc(1.25rem - 11px);
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.5;
  z-index: 1001;
}

nav li {
  display: flex;
  position: relative;
  list-style-type: none;
  text-align: -webkit-match-parent;
  z-index: 1003;
}

nav a {
  font-size: clamp(0.875rem, 0.7985rem + 0.3265vw, 1.125rem);
  border-radius: 1.5rem;
  text-decoration: none;
  color: hsla(159, 42%, 88%, 1);
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  padding-right: calc(1.25rem - 5px);
  margin: 0 5px;
  padding: calc(1rem - 10px) 1.25rem;
  cursor: pointer;
  z-index: 1004;
}

.slash {
  display: flex;
  font-size: calc(0.875rem - 2px);
  color: hsla(160, 52%, 71%, 1);
  box-shadow: inset 0 0 0 2px hsla(160, 52%, 71%, 0.2);
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-left: calc(0.875rem - 8px);
  pointer-events: none;
  z-index: 1003;
}

.background {
  position: absolute;
  left: var(--left);
  width: var(--width);
  height: 36px;
  top: calc(1.25rem + 6.5px);
  background: hsla(160, 52%, 71%, 0.1);
  border-radius: 1.5rem;
  display: block;
  font-size: var(--16px);
  opacity: 1;
  transition: all 0.2s ease-in-out;
  z-index: 1002;
  pointer-events: none;
}

nav ul.glass {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 40px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}
