*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
textarea {
  font-weight: 700;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
}

.screenWrap {
  display: contents;
}
.screenContainer {
  width: 70%;
  max-width: 750px;
  flex: 0 1 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  padding: 10px;
}

.screen {
  position: relative;
  background: #29453c;
  border-radius: 15px;
}

.screen:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 18px 20px;
  background: #1b2d27;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 10px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #ee2049;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 6px;
  height: 6px;
  margin-left: 4px;
  border-radius: 8px;
  background: #8fdcc2;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px 50px 30px 50px;
  overflow: hidden;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ea1d6f;
}

.app-title:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #ea1d6f;
}

.app-contact {
  margin-top: auto;

  color: #8fdcc2;
}

.app-form-group {
  margin-bottom: 15px;
  overflow: hidden;
  text-align: bottom;
}

.app-form-group.message {
  margin-top: 15px;
}

textarea {
  padding: 0 !important;
}
.app-form-group.buttons {
  margin-bottom: 0;
  text-align: center;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #8fdcc2;
  color: #ddd;

  outline: none;
  transition: border-color 0.2s;
  -webkit-appearance: none;
}

.app-form-control::placeholder {
  color: #8fdcc2;
  opacity: 0.6;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}

.app-form-button {
  border: none;

  cursor: pointer;
  outline: none;
  margin-top: 2rem;
  background: hsla(160, 52%, 71%, 1);
  color: hsla(158, 23%, 18%, 1);
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  text-align: center;
  letter-spacing: -0.3px;
}

.app-form-button:hover {
  background: #d4ede4;
}
.submit-message-false {
  opacity: 0;
  color: hsla(160, 52%, 71%, 1);
  margin-top: 1rem;
  text-align: center;

  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: all 0.2s;
}
.submit-message-true {
  opacity: 1;
  color: hsla(160, 52%, 71%, 1);
  margin-top: 1rem;
  text-align: center;

  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: all 0.3s;
}

.error {
  color: #ef1f49;
  font-size: 1rem;
  font-family: "gt", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.hidden {
  visibility: hidden;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}

@media (max-width: 560px) {
  .screenContainer {
    padding: 0;
  }
  .screen-header {
    padding: 10px 17px;
  }
  .screen-body {
    padding: 35px;
    padding-bottom: 15px;
  }
  .app-form-control {
    font-size: 0.65rem;
  }
  .error {
    font-size: 0.65rem;
  }
  .message {
    margin-bottom: 0;
  }
  .app-form-button {
    font-size: 0.7rem;
  }
  .app-form-button {
    margin-top: 1rem;
  }
  .submit-message-false {
    font-size: 0.8rem;
  }
  .submit-message-true {
    font-size: 0.8rem;
  }
}
@media (max-width: 465px) {
  .screenContainer {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}
